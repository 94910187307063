@import "../../assets/styles/variables";
@import "../../assets/styles/fonts";

.thank-you-component {
    max-width: @screen-xs;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80vh;
    padding: 0 1em 1em 1em;
        .logo-container {
            margin: 1em auto;
            text-align: center;
            .logo {
                height: auto;
                width: 15em;
            }
        }
        .text-container{
			text-align: center;
			.title{
				text-align: center;
                font-size: 2em;
                margin: 0.1em 0;
			}
			.content-text{
				.SourceSansPro-Bold;
				font-size: 1.2em;
			}
		}
}

