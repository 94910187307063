@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

.ftui-footer {
	position: relative;
	overflow: visible;
	min-height: 900px;
	max-width: 1600px;
	margin: 0 auto;
	pointer-events: none;

	&:before {
		content: '';
		display: block;
		position: absolute;
		z-index: 0;
		top:0;
		width: 100%;
		//box-shadow: 0 0 30px  50px rgba(0,0,0,0.5);
	}

	.inner-container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.text-container {
			position: relative;
			color: white;
			.Quantify-Bold;
			font-size: 32px;
			text-align: center;
			padding: 30px;
			z-index: 2;
		}

		.left-image-container{
			position: relative;
			width: 600px;
			margin-top: -110px;
			margin-left: 130px;

			.left-image-bottom {
				position: relative;
				display: block;
				width: 100%;
				height: auto;
				z-index: -1;
			}
			.left-image-top {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: auto;
				z-index: 0;
			}
		}
	}

	@media (min-width: @grid-sm) {
		.inner-container{
			.text-container {
				padding: 30px 48px ;
				font-size: 44px;
			}

			.left-image-container {
				width: 800px;
				margin-top: -150px;
				margin-left: 160px;
			}
		}
	}
	@media (min-width: @grid-md) {
		.inner-container{
			.text-container {
				padding: 30px 65px;
				font-size: 58px;
			}

			.left-image-container {
				width: 1000px;
				margin-top: -200px;
				margin-left: 240px;
			}
		}
	}
	@media (min-width: @grid-lg) {
		.inner-container{
			display: block;

			.text-container {
				padding: 30px 65px 30px 0;
				font-size: 54px;
				text-align: right;
				width: 581px;
				margin-left: auto;
				margin-right: 0;
			}

			.left-image-container {
				position: absolute;
				width: 1000px;
				margin : 0;
				top: -150px;
				left: -140px;
			}
		}
	}
	@media (min-width: @grid-xl) {
		.inner-container{
			.text-container {
				padding: 30px 121px 30px 0;
				font-size: 70px;
				width: 862px;
			}

			.left-image-container {
				width: 1200px;
				top: -170px;
				left: -170px;
			}
		}
	}
	@media (min-width: @grid-xxl) {
		.inner-container{

			.text-container {
				padding: 30px 134px 30px 0;
				font-size: 79px;
				width: 947px;
			}

		}
	}

}
