@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.card-details-payment {
	.component-body{
		padding-bottom: 30px;
		
		.text-container {
			.title {
				text-shadow: none;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
			}
		}

		.text-container{
			text-align: center;
			.title{
				text-align: center;
			}
			.content-text{
				padding-top: 5px;
				.SourceSansPro-Bold;
				font-size: 21px;
			}

			padding: 21px 10px 40px;
			@media (min-width: @grid-sm) {
				padding: 21px 48px 40px;
				.title{
					font-size: 67px;
				}
				.content-text{
					font-size: 26px;
				}
			}
			@media (min-width: @grid-md) {
				padding: 57px 64px 40px;
			}
			@media (min-width: @grid-lg) {
				padding: 60px 96px 40px;
				.title{
					font-size: 69px;
				}
				.content-text{
					font-size: 31px;
				}
			}
			@media (min-width: @grid-xxl) {
				padding: 60px 0 40px;
				margin: 0 auto;
				max-width: 1200px;
			}
		}
		
		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 10px;
			justify-content: center;

			.common-button{
				margin-bottom: 15px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			@media (min-width: @grid-sm) {
				flex-direction: row;
				margin: 0 48px;

				.common-button{
					margin-bottom: 0;
					margin-right: 20px;

					&:last-of-type {
						margin-right: 0
					}
				}
			}
			@media (min-width: @grid-md) {
				margin: 0 64px;

				.common-button{
					width: 240px;
				}
			}
			@media (min-width: @grid-lg) {
				margin: 0 83px;
			}
			@media (min-width: @grid-xxl) {
				margin: 0 auto;
				max-width: 1333px;
			}
		}
	}
}
