@import "../../assets/styles/variables";

.payment-selection {
	width: 100%;
	text-align: center;

	.logo {
		height: auto;
		width: 10em;
		margin: 0.5em;
	};

	>h1 {
		line-height: 1;
		text-align: center;
		margin: 0.1em 0;
	}

	>.description {
		font-size: 1em;
		max-width: 800px;
		margin: 30px 10px 40px;

		@media (min-width: @grid-md) {
			margin: 30px auto 40px;
		}
	}

	.plans-buttons-container {
		display: flex;
		max-width: 800px;
		margin: 0 auto;
	}

	.plans-container {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		.spinner-container {
			position: relative;
			width: 5em;
			height: 5em;
			.loader {
				position: static;
			}
		}
		@media (min-width: @grid-md) {
			flex-direction: row;
			align-items: initial;
			justify-content: center;
		}
	}

	.text-container {
		text-align: center;
		margin: 20px auto;
		max-width: 800px;

		.spacer {
			margin: 0 10px;
			height: 3px;
			background-color: white;
		}

		@media (min-width: @grid-sm) {
			.spacer{
				width: 500px;
				margin: 0 auto;
			}
		}
	}

	.buttons-container {
		padding-top: 1em;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 10px;

		.common-button{
			margin-bottom: 10px;
			border-radius: 3px;

			.content{
				font-weight: bold;
				padding: 15px 0;
			}

			&.back-button {
				margin-bottom: 0;
				margin-top: 50px;

				.content{
					font-weight: normal;
				}

			}
		}

		@media (min-width: @grid-sm) {
			margin: 0 48px;

			.common-button{
				width: 240px;
			}
			.paypal-button-component{
				width: 240px;
			}
		}
		@media (min-width: @grid-md) {
			margin: 0 64px;
		}
		@media (min-width: @grid-lg) {
			margin: 0 83px;
		}
		@media (min-width: @grid-xxl) {
			margin: 0 auto;
			max-width: 1333px;
		}
	}
}