
.popup-overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0;
	width:100%;
	height: 100%;
	z-index: 101;
	pointer-events:none;

	&.active {
		display: block;
		opacity: 0.8;
	}
}