@import '../../assets/styles/variables';

.homepage-component {
	max-width: @screen-xs;
	margin: 0 auto;
	padding: 50px 10px 0;

	.common-button {
		margin-bottom: 10px;
	}

	.line {
		height: 2px;
		background: @font-color;
		margin: 10px 0 30px;
	}

	.subscription-details {
		>h5 {
			margin: 0 0 10px 10px;
		}
	}
}

.loader-games {
    position:absolute;
    left:calc(50% - 50px);
    top:30%;
    width:100px;
    height:50%;
}
