@import "../../../assets/styles/fonts";
@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.gdpr {
	.ftui-background-mixing;
	padding-bottom: 26px;
	@media (min-width: @grid-sm) {
		padding-bottom: 54px;
	}
	@media (min-width: @grid-lg) {
		padding-bottom: 65px;
	}

	.component-body {
		margin: 30px 10px;
		@media (min-width: @grid-sm) {
			margin: 30px 48px;
		}
		@media (min-width: @grid-md) {
			margin: 30px 64px;
		}
		@media (min-width: @grid-lg) {
			margin: 30px 83px;
		}
		@media (min-width: @grid-xxl) {
			margin: 30px auto;
			max-width: 1333px;
		}

		.gdpr-title{
			.Quantify-Bold;
			font-size: 24px;
			text-align: center;
		}
		.gdpr-text{
			.Lato-Regular;
			font-size: 24px;

			>p{
				margin: 20px 0;
				&:first-of-type{
					.Quantify-Bold;
					font-size: 34px;
					line-height: 34px;
				}
			}
		}

	}

	.buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 10px;

		.common-button{
			margin-bottom: 15px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		@media (min-width: @grid-sm) {
			margin: 0 48px;
		}
		@media (min-width: @grid-md) {
			flex-direction: row;
			margin: 0 64px;
			.common-button {
				margin-bottom: 0;
				margin-right: 20px;
				&:last-of-type {
					margin-right: 0;
				}
			}
		}
		@media (min-width: @grid-lg) {
			margin: 0 83px;
		}
		@media (min-width: @grid-xxl) {
			margin: 0 auto;
			max-width: 1333px;
		}
	}
}
