@import '../../../assets/styles/variables';

.component-body {
    height: 100%;
}

.simple-registration-component {
    max-width: @screen-xs;
    margin-left: auto;
    margin-right: auto;
    &.simple-registration-component--center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .logo-container {
        margin: 2em auto;
        text-align: center;
        .logo {
            height: auto;
            width: 15em;
        }
    }
    .facebook-container {
        margin: 2em auto;
        text-align: center;
        .common-button.with-facebook {
            margin-bottom: 3em;
        }
    }
    .form-field-container {
        padding: 1em;

        .input-container {
            text-align: left;
            margin-bottom: 1em;
        }


        .buttons-container {
            width: 100%;
            .common-button {
                width: 48%;
                &:first-of-type {
                    margin-right: 4%;
                }
            }
        }
    }
    .privacy_policy_container {
        margin: 10px 0 30px;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: SourceSansPro-Regular,sans-serif;
        font-weight: 400;
        color: #9fa8b5;
        font-size: 14px;
        .privacy_policy-text {
            margin-left: 1em;
        }
    }
}

