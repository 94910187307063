@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.generic-popup-component {
	position: relative;
	width: 100%;
	max-width: 23em;
	padding: 1em 0.7em 0.8em;

	&:before {
		.popup-background-mixing;
	}

	.title-text {
		text-align: center;
		width: 100%;
		font-family: 'Quantify-Bold';
		letter-spacing: 0.05em;
		font-size: 1.7em;
		color: @font-color-title;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
	}

	.message-text {
		width: 100%;
		font-size: 1.2em;
		text-align: center;
		text-shadow: 0.05em 0.05em 0.1em rgba(0,0,0,0.2);
		color: @font-color-title;
		padding: 0.7em;
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		.common-button {
			width: 25%;
			margin-right: 1em;
		}
	}
}