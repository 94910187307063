@import '../../assets/styles/variables';

.docomo-registration-component {
    max-width: @screen-xs;
    margin-left: auto;
    margin-right: auto;

        .logo-container {
            margin: 2em auto;
            text-align: center;
            .logo {
                height: auto;
                width: 15em;
            }
        }
        .form-field-container {
            padding: 1em;

            .input-container {
                text-align: left;
                margin-bottom: 1em;
            }
    
    
            .buttons-container {
                width: 100%;
                .common-button {
                    width: 48%;
                    &:first-of-type {
                        margin-right: 4%;
                    }
                }
            }
        }    
}

