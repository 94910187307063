@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.checkbox-container{
    position: relative;
    border: 0.2em solid @font-color-gdpr;
    box-shadow: 0 0.1em 0.2em rgba(0,0,0,0.05), inset 0 -0.15em 0.1em -0.12em rgba(0,0,0,0.05);
    border-radius: 0.3em;
    height: 3em;
    width: 3em;
    cursor: pointer;
    margin: 0 0.1em;
    font-size: 0.6em;
    flex-shrink: 0;

    &:focus {
        border: 0.2em solid @focus-outline;
    }

    &.checked {
        &:after{
            position: absolute;
            top: -10%;
            left: -10%;
            content: "";
            width: 120%;
            height: 120%;
            background:  url(../../../assets/images/global/tickAssetWhite.svg) 0 0 no-repeat;
            pointer-events: none;
        }
    }
}