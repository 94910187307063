.popup-animation {
    position:absolute;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;

    z-index:102;
    display: none;

	&.show {
		display: flex;
		opacity: 0;
		animation: popup-show-animation 0.35s cubic-bezier(0.175, 0.885, 0.320, 1.350);
		animation-fill-mode: forwards;
	}
	&.hide {
		display: flex;
		animation: popup-hide-animation 0.35s cubic-bezier(0.600, -0.350, 0.735, 0.045);
		animation-fill-mode: forwards;
	}

	@keyframes popup-show-animation {
		1% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
			opacity: 1;
		}
	}

	@keyframes popup-hide-animation {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(0);
		}
	}
}

