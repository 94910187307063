@import '../../assets/styles/variables';
@import '../../assets/styles/animations';

.login-wrapper {
	max-width: @screen-xs;
	margin-left: auto;
	margin-right: auto;

	.login-section {
		text-align: center;
		font-weight: bold;
		h1{
			margin-top: 0.5em;
			text-align: center;
		}

		p{
			text-align: left;
		}

		.logo-container {
			margin: 2em auto;
			text-align: center;
			img {
				height: auto;
				width: 15em;
			}

			&--big {
				margin: 8em auto;
				img {
					height: auto;
					width: 18em;
				}
			}
		}

		.common-button{
			width: 100%;
			margin-bottom: 1em;

			&.with-facebook{
				width: auto;
			}
		}



		.input-container {
			text-align: left;
			margin-bottom: 1em;
		}

		.right-button {
			display: block;
			width: 50%;
			margin-left: auto;
			margin-right: 0;
		}

		.small-button {
			width: 50%;
		}

		.buttons-container {
			width: 100%;
			.common-button {
				width: 48%;
				&:first-of-type {
					margin-right: 4%;
				}
			}
		}

		.buttons-container-vertical {
			margin-top: 10em;
			.common-button {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.separator-with-text {
			width: 100%;
			margin: 0 0 1em;

			.line {
				display: inline-block;
				position: relative;
				top: -0.30em;
				width: 42%;
				height: 2px;
				background-color: @font-color;
			}

			.text {
				display: inline-block;
				width: 16%;
				color: @font-color;
				font-size: 1em;
				font-family: 'Lato', sans-serif;
				font-weight: bold;
				letter-spacing: 0.09em;
				text-align: center;
			}
		}

		.remote-validation-error {
			height: 1.5em;
			color: @font-color-validation;
		}

		&--animate{
			animation: 0.5s bounce ease-in-out;
		}
		&--avatar{
			text-align: center;

			h1{
				margin-top: 0.5em;
				text-align: center;
			}

			.common-button {
				margin: 1em;
				width: auto;
			}
		}
	}
}
