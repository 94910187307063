@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

.navigation-bar{
	margin: 0 auto;
	padding: 7px 15px 0 10px;
	@media (min-width: @grid-sm) {
		padding-left: 48px;
		padding-right: 55px;
	}
	@media (min-width: @grid-md) {
		padding-left: 64px;
		padding-right: 69px;
	}
	@media (min-width: @grid-lg) {
		padding-top: 40px;
		padding-left: 83px;
		padding-right: 88px;
	}
	@media (min-width: @grid-xxl) {
		padding-left: 0;
		padding-right: 0;
		max-width: 1333px;
	}

	.navigation-bar-item-container{
		.navigation-bar-item {
			display: inline-block;
			width: calc(100% / 3);
			border: solid 0;
			position: relative;
			height: 10px;

			.item-name {
				color: @navigation-bar-text-color;
				text-align: center;
				display: none;
			}

			&:first-of-type {
				border-radius: @navigation-bar-border-radius-small;
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				right: -5px;
				top: 0;

				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid green;
			}

			@media (min-width: @grid-md) {
				height: 15px;
				&:after{
					right: -7.5px;

					border-top: 7.5px solid transparent;
					border-bottom: 7.5px solid transparent;
					border-left: 7.5px solid green;
				}
			}
			@media (min-width: @grid-lg) {
				height: 40px;

				&:first-of-type{
					border-radius: @navigation-bar-border-radius-large;
				}
				&:after{
					right: -20px;

					border-top: 20px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 20px solid green;
				}
				.item-name {
					line-height: 40px;
					display: block;
				}
			}

			&.past{
				z-index: 7;
				background-color: @navigation-bar-bg-color-purple;
				&:after{
					z-index: 7;
					border-left-color: @navigation-bar-bg-color-purple;
				}
			}
			&.current{
				z-index: 6;
				background-color: @navigation-bar-bg-color-light-purple;
				&:after{
					z-index: 6;
					border-left-color: @navigation-bar-bg-color-light-purple;
				}
			}
			&.next-1{
				z-index: 5;
				background-color: @navigation-bar-bg-color-gray-1;
				&:after{
					z-index: 5;
					border-left-color: @navigation-bar-bg-color-gray-1;
				}
			}
			&.next-2{
				z-index: 4;
				background-color: @navigation-bar-bg-color-gray-2;
				&:after{
					z-index: 4;
					border-left-color: @navigation-bar-bg-color-gray-2;
				}
			}
			&.next-3{
				z-index: 3;
				background-color: @navigation-bar-bg-color-gray-3;
				&:after{
					z-index: 3;
					border-left-color: @navigation-bar-bg-color-gray-3;
				}
			}
			&.next-4{
				z-index: 2;
				background-color: @navigation-bar-bg-color-gray-4;
				&:after{
					z-index: 2;
					border-left-color: @navigation-bar-bg-color-gray-4;
				}
			}
			&.next-5{
				z-index: 1;
				background-color: @navigation-bar-bg-color-gray-5;
				&:after{
					z-index: 1;
					border-left-color: @navigation-bar-bg-color-gray-5;
				}
			}
		}
	}

	.current-item-name {
		.SourceSansPro-Regular;
		color: @navigation-bar-bg-color-light-purple;
		font-size: 14px;
		text-align: center;

		@media (min-width: @grid-sm) {
			font-size: 18px;
		}
		@media (min-width: @grid-lg) {
			display: none;
		}
	}
}
