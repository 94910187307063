@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
@import '../../assets/styles/fonts';

.payment-component {
	&.standalone{
		max-width: 640px;
		margin: 0 auto;
	}

	.title-text {
		display: none;
		margin-top: 1em;
		text-align: center;
	}

	.info-text {
		display: block;
		margin: 0 auto;
		text-align: center;
		padding: 0 10px;
		max-width: 640px;
	}

	.card-details-container {
		text-align: center;
		padding: 0 0 0;

		.payment-request-button {
			display: block;
			margin: 0 auto 15px;
		}

		.or-text-container {
			.SourceSansPro-Regular;
			font-size: 14px;
			color:@border-color-input-component;
		}

		.card-form {
			margin: 15px auto 45px;
			width: 300px;

			@media (min-width: @grid-sm) {
				width: 480px;
			}
			@media (min-width: @grid-md) {
				width: 640px;
			}
			@media (min-width: @grid-lg) {
				width: 640px;
			}
		}
	}

	.logo-container {
		margin: 2em auto;
		text-align: center;
		img {
			height: auto;
			width: 15em;
		}
	}
}