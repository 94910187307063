@import '../../../assets/styles/variables';
@import '../../../assets/styles/fonts';

.input-container {
	position: relative;
	display: inline-block;
	width: 100%;
	background-color: @background-button-disabled;
	border-radius: 8px;
	border: solid 2px @border-color-input-component;
	transform: skew(-5deg);

	&.disabled {
		opacity: 0.5;
	}

	&.selected {
		border-color: @font-color-outline-purple;
	}

	input {
		width: 100%;
		padding: 10px 15px;

		.SourceSansPro-Regular;
		font-size: 24px;
		color: @font-color-button-secondary;
		line-height: 1em;

		background: none;
		border: none;

		transform: skew(5deg);

		&:-webkit-autofill {
			background: none !important;
			-webkit-text-fill-color:  @font-color-button-secondary  !important;
			caret-color: @font-color-button-secondary;
		}

		&::placeholder {
			color: @border-color-input-component;
		}

		&::-moz-focus-inner {
			border: 0;
		}
	}

	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	.validation-error {
		position: absolute;
		top: -26px;
		right: 10px;
		padding: 5px;
		background-color: #A82B4C;

		.SourceSansPro-Regular;
		font-size: 14px;
		color: white;
	}
}
