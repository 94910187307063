@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";

.landing {
	position:relative;
	min-height: 1500px;
	text-align: center;
	overflow: hidden;

	@media (min-width: @grid-sm) {
		text-align: left;
	}

	@media (min-width: @grid-lg) {
		//min-height: 900px;
	}

	@media (min-width: @grid-xxl) {
		height: 100vh;
	}

	.background-image {
		display: block;
		position: absolute;
		top: -300px;
		left: -350px;
		z-index: -1;
		width: 1100px;

		@media (min-width: @grid-md) {
			left: initial;
			right: 0;
			width: 1200px;
		}
		@media (min-width: @grid-xl) {
			left: initial;
			top: -700px;
			right: 0;
			width: 1500px;
		}
		@media (min-width: @grid-xxl) {
			top: -900px;
			width: 100%;
			height: auto;
		}
	}

	.text-container {
		.Lato-Bold;
		font-size: 39px;
		text-shadow: 0 4px 4px #000000;
		padding: 100px 12px 0;

		@media (min-width: @grid-sm) {
			padding: 110px 220px 0 40px;
		}
		@media (min-width: @grid-md) {
			padding-top: 150px;
			padding-right: 321px;
		}
		@media (min-width: @grid-lg) {
			padding-top: 160px;
			padding-right: 560px;
		}
		@media (min-width: @grid-xxl) {
			padding: 180px 790px 0 80px;
		}
	}

	.text-container-small {
		.Lato-Regular;
		font-size: 17px;
		text-shadow: 0 4px 4px #000000;
		padding: 30px 12px 15px ;
		@media (min-width: @grid-sm) {
			padding: 30px 220px 15px 40px;
		}
		@media (min-width: @grid-md) {
			padding-right: 321px;
		}
		@media (min-width: @grid-lg) {
			padding-right: 560px;
		}
		@media (min-width: @grid-xxl) {
			padding-left: 80px;
			padding-right: 790px;
		}
	}

	.buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		padding-left: 12px;

		@media (min-width: @grid-sm) {
			align-items: flex-start;
			padding-left: 40px;
		}
		@media (min-width: @grid-xxl) {
			padding-left: 80px;
		}

		.common-button {
			width: 240px;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
		}

		.login-button-text-container {
			.Lato-Regular;
			font-size: 17px;
			text-shadow: 0 4px 4px #000000;
			padding: 50px 0 15px;
		}
	}
}
