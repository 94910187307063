@import "../../assets/styles/variables";

.payment-plan-item {
	position: relative;
	background: @ftui-plan-item-background-color;
	max-width: 300px;
	min-height: 110px;
	margin: 1em 1.5em;
	padding: 0.1em 2em;
	cursor: pointer;
	border-radius: 6px;
	h1,h2,h5 {color: #C3C9D5;}
	&.selected {
		.pulsar-component {
			display: block;
		}
	}
	
	&.payment-plan-item-v2 {
		width: 265px;
		padding: 0.1em 0;
		h2 {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			max-width: 200px;
		}
	}

	&.active{
		h1,h2,h5 {color: #ffffff;}
		background: @ftui-plan-item-background-color-active;
	}

	.content-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1em;
		height: calc(100% - 0.1em);

		h1 {
			margin-bottom: 0;
		}
		.description {
			color: #C3C9D5;
			font-size: 10px;
		}
	}

	&.payment-plan-item-v2 {
		.content-container {
			h1 {
				font-size: 64px;
				margin-bottom: 10px;
			}
			.description {
				color: #fdfdfd;
				font-size: 16px;
			}
		}
	}

	.best-value {
		display: none;
	}

	&.annual {
		.best-value {
			transform: rotate(-20deg);
			display: block;
			position: absolute;
			top: -2.5em;
			left: -2.5em;

			.title {
				position: absolute;
				color: white;
				top: 30px;
				left: 30px;
				width: 35px;
				text-align: center;
				font-size: 12px;
			}
		}
	}
}