@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/fonts";

.plan-selection {
	.component-body{
		.component-title {
			text-shadow: none;
			font-style: normal;
			font-weight: bold;
			text-align: center;
			font-size: 35px;
			margin: 30px 0 15px;
		}

		.text-container {
			text-align: center;
			margin: 20px auto;
			max-width: 1200px;

			.spacer {
				margin: 0 10px;
				height: 3px;
				background-color: white;
			}

			@media (min-width: @grid-sm) {
				.spacer{
					width: 500px;
					margin: 0 auto;
				}
			}
		}

		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 10px;
			padding-bottom: 50px;

			.common-button{
				margin-bottom: 10px;
				border-radius: 3px;

				.content{
					font-weight: bold;
					padding: 15px 0;
				}

				&.promo-button {
					margin-bottom: 0;
					margin-top: 50px;

					.content{
						font-weight: normal;
					}

				}
			}

			@media (min-width: @grid-sm) {
				margin: 0 48px;

				.common-button{
					width: 240px;
				}
				.paypal-button-component{
					width: 240px;
				}
			}
			@media (min-width: @grid-md) {
				margin: 0 64px;
			}
			@media (min-width: @grid-lg) {
				margin: 0 83px;
			}
			@media (min-width: @grid-xxl) {
				margin: 0 auto;
				max-width: 1333px;
			}
		}
	}
}
