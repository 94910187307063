@import "assets/styles/variables";
@import "assets/styles/mixins";
@import "assets/styles/animations";
@import "assets/styles/fonts";
@import "assets/styles/global";
@import "assets/styles/icons";

html, body {
	height: 100%;
}
.main {
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
	height: 100%;

	.app-main-container {
		width: 100%;
		height: 100%;
		background: url('assets/images/ftui/auth-flow-background.jpg') 50% 50% no-repeat;
		background-size: cover;

		@media screen and (min-width: @screen-md) {
			margin: 0 auto;
		}

		&.active {
			pointer-events:none;
		}
	}
}