.common-button{
	&.with-facebook {
		padding: 0.4em 0.4em 0.3em;
		background-color:  #4267B2;
		transform: skew(0);
		width: auto;
		color: white;

		.content {
			display: inline-flex;
			align-items: center;
			height: auto;
			transform: skew(0);
			font-family: "Helvetica", "Arial", "sans-serif";
			padding: 5px;


				.facebook-image {
					padding-right: 0.4em;
					width: auto;
					height: 1.5em;
				}

			.text-container{
				padding: 0 0.3em;
			}
		}

		&.bounce-animation {
			animation: button-bounce-frames-facebook-button 150ms;
		}

		@keyframes button-bounce-frames-facebook-button {
			0% { transform: scale(1); }
			50% { transform: scale(0.9); }
			100% { transform: scale(1); }
		}
	}
}


