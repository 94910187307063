@import '../../assets/styles/variables';

.update-profile-component {
	max-width: @screen-xs;
	margin: 0 auto;
	padding-top:30px;

	.common-button {
		margin-bottom: 10px;
	}

	.line {
		height: 2px;
		background: @font-color;
		margin: 20px 0 30px;
	}

	.user-details {
		display: flex;

		.image-container {
			width: 100px;

			.profile-image {
				cursor: pointer;
				width: 100px;
				height: auto;
			}
		}

		.details-container {
			padding: 0 0 0 10px;
			width: calc(100% - 100px);

			.input-container {
				margin-bottom: 10px;
			}

			.common-button {
				margin: 10px 0 0;
			}

			.hidden-save-button {
				height: 0;
				margin: 0;
				padding: 0;
				border: none;
				opacity: 0;
			}
		}
	}
}