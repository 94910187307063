@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.avatar-selection {
	width: 100%;
	.component-body{
		.ftui-background-mixing;
		padding-bottom: 26px;
		@media (min-width: @grid-sm) {
			padding-bottom: 45px;
		}
		@media (min-width: @grid-lg) {
			padding-bottom: 65px;
		}

		.avatar-section {
			position: relative;
			margin: 15px 10px;

			@media (min-width: @grid-sm) {
				margin: 20px 48px;
			}
			@media (min-width: @grid-md) {
				margin: 80px 64px;
			}
			@media (min-width: @grid-lg) {
				margin: 80px 83px;
			}
			@media (min-width: @grid-xxl) {
				margin: 113px auto;
				max-width: 1333px;
			}

			.selected-avatar {
				display: block;

				.avatar-component {
					display: block;
					height: 135px;
					width: 135px;
					margin: 0 auto 10px;

					border: 2.5px solid @avatar-border;

					@media (min-width: @grid-sm) {
						margin: 0 auto 19px;
					}
					@media (min-width: @grid-md) {
						height: 150px;
						width: 150px;
						margin: 0;
					}
					@media (min-width: @grid-lg) {
						height: 175px;
						width: 175px;
					}
					@media (min-width: @grid-xxl) {
						height: 200px;
						width: 200px;
					}
				}

				@media (min-width: @grid-md) {
					position: absolute;
					top: -30px;
					left: 0;
					overflow: visible;
					z-index: 2;
				}
				@media (min-width: @grid-lg) {
					top: -42px;
				}
			}

			.avatar-container {
				overflow: scroll;

				.avatar-inner-container{
					display: inline-flex;
					flex-direction: row;
					align-items: center;

					.avatar-component {
						height: 50px;
						width: 50px;
						margin: 5px;

						@media (min-width: @grid-sm) {
							height: 70px;
							width: 70px;
						}
						@media (min-width: @grid-md) {
							height: 90px;
							width: 90px;
						}
						@media (min-width: @grid-xxl) {
							height: 100px;
							width: 100px;
						}
					}
				}

				@media (min-width: @grid-md) {
					margin-left: 100px;
					padding-left: 50px;
				}
				@media (min-width: @grid-lg) {
					margin-left: 100px;
					padding-left: 75px;
				}
				@media (min-width: @grid-xxl) {
					margin-left: 100px;
					padding-left: 100px;
				}
			}
		}

		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 10px;

			.common-button{
				&:first-of-type {
					margin-bottom: 15px;
				}
			}

			@media (min-width: @grid-sm) {
				flex-direction: row;
				margin: 0 48px;

				.common-button{
					&:first-of-type {
						margin-bottom: 0;
						margin-right: 20px;
					}
				}
			}
			@media (min-width: @grid-md) {
				justify-content: flex-end;
				margin: 0 64px;

				.common-button{
					width: 240px;
				}
			}
			@media (min-width: @grid-lg) {
				margin: 0 83px;
			}
			@media (min-width: @grid-xxl) {
				margin: 0 auto;
				max-width: 1333px;
			}
		}
	}
}
