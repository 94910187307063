@import '../../assets/styles/variables';

.header-component {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	padding: 10px 0;
	max-width: @screen-xs;

	.logo-img {
		width: auto;
		height: 50px;
	}

	.user-profile {
		display: flex;
		flex-direction: row;
		align-items: center;

		.user-name {
			display: inline;
			margin: 0 5px;
			text-align: right;
		}

		.profile-image {
			cursor: pointer;
			width: auto;
			height: 50px;
		}
	}
}