.icon i {
	display: block;
}

///* Top genre icons */
//
//.icon i.Action {
//	background: url(../images/genre-icons/action.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Adventure {
//	background: url(../images/genre-icons/adventure.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Arcade,
//.icon i.ArcadeAdventure {
//	background: url(../images/genre-icons/arcade-adventure.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.BeatEmUp {
//	background: url(../images/genre-icons/beat-em-up.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Driving {
//	background: url(../images/genre-icons/driving.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Fighting {
//	background: url(../images/genre-icons/fighting.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.FlightSimulator {
//	background: url(../images/genre-icons/flight-simulator.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Maze {
//	background: url(../images/genre-icons/maze.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Pinball {
//	background: url(../images/genre-icons/pinball.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Platform {
//	background: url(../images/genre-icons/platform.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Puzzle {
//	background: url(../images/genre-icons/puzzle.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Racing {
//	background: url(../images/genre-icons/racing.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.RolePlayingGame {
//	background: url(../images/genre-icons/role-playing-game.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Shooting {
//	background: url(../images/genre-icons/shooting.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Sports {
//	background: url(../images/genre-icons/sports.svg) 0 0 no-repeat;
//	background-size: contain;
//}
//
//.icon i.Strategy {
//	background: url(../images/genre-icons/strategy.svg) 0 0 no-repeat;
//	background-size: contain;
//}