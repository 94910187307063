/*
 * @author Dan Jeffrey
 * @email dan@danj.me
 * @file splash.css
 * @description Splash CSS
 *
 * http://www.danj.me/
 * https://twitter.com/danjme
 */

.splash-component {
	position: relative;
	top: -4em;
	display: block;
	margin: 0 auto 1em;
	text-align: center;
	transform-style: preserve-3d;
	height:100vh;

	.logo {
		position: relative;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		text-align: center;
	}
}
