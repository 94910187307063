@import "variables";

html, body {
	touch-action: manipulation;
	-ms-touch-action: none;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

/* remove this after refactor all ul li usages*/
ol, ul {
	list-style: none;
}

* {
	box-sizing: border-box;
	outline: @font-color-outline-purple;
	-webkit-tap-highlight-color: transparent;
	-ms-overflow-style: none;
}

*::-webkit-scrollbar {
	display: none;
	width: 0;
	background: transparent; /* make scrollbar transparent */
}

body {
	line-height: 1;
	color: @font-color;
	font-family: "Lato", sans-serif;
	-webkit-font-feature-settings: "liga" 0, "clig" 0;
	-moz-font-feature-settings: "liga" 0, "clig" 0;
	font-feature-settings: "liga" 0, "clig" 0;
	zoom: 1.0;
	transform: scale(1);
	overflow-y: scroll;
	min-height: 100vh;
	width: 100vw;
	background: @background;


	&.iPhoneX {
		//font-size: 11px;
	}
}

#root {
	width: 100%;
	height: 100%;
}

a {
	display: inline-block;
	color: @font-color-text-shadow;
}

h1 {
	line-height: 1.12;
	margin-bottom: 0.5em;
	color: @font-color-title;
	text-shadow: 0 0 0.25em @font-color-text-shadow;
	text-align: left;
	font-size: 2.25em;
	font-family: 'Quantify-Bold';
	letter-spacing: 0.065em;
	user-select: none;
}

h2 {
	line-height: 1.4;
	margin-bottom: 0.3em;
	color: @font-color-title;
	text-align: left;
	font-size: 1.5em;
	letter-spacing: 0.04em;
	user-select: none;
}

h3 {
	margin-bottom: 0.67em;
	color: @font-color-title;
	font-size: 1em;
	user-select: none;
}

p {
	line-height: 1.5;
	margin-bottom: 1em;
	font-family: 'SourceSansPro-Regular', sans-serif;
	font-size: 1em;
	font-weight: 300;
	user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #001B2F inset;
  transition: background-color 5000s ease-in-out 0s;
}
