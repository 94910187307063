@import "../../../assets/styles/variables";
@import "../../../assets/styles/fonts";
@import "../../../assets/styles/mixins";


.account-created {
	.component-body{
		.ftui-background-mixing;
		padding-bottom: 26px;
		@media (min-width: @grid-sm) {
			padding-bottom: 54px;
		}
		@media (min-width: @grid-lg) {
			padding-bottom: 65px;
		}

		.text-container{
			text-align: center;
			.title{
				text-align: center;
				font-size: 43px;
				margin: 5px 0;
			}
			.content-text{
				.SourceSansPro-Bold;
				font-size: 21px;
			}

			padding: 21px 10px 40px;
			@media (min-width: @grid-sm) {
				padding: 21px 48px 40px;
				.title{
					font-size: 67px;
				}
				.content-text{
					font-size: 26px;
				}
			}
			@media (min-width: @grid-md) {
				padding: 57px 64px 40px;
			}
			@media (min-width: @grid-lg) {
				padding: 60px 96px 40px;
				.title{
					font-size: 69px;
				}
				.content-text{
					font-size: 31px;
				}
			}
			@media (min-width: @grid-xxl) {
				padding: 60px 0 40px;
				margin: 0 auto;
				max-width: 1200px;
			}
		}

		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 10px;

			.common-button{
				margin-bottom: 15px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}

			@media (min-width: @grid-sm) {
				margin: 0 138px;
			}
			@media (min-width: @grid-md) {
				flex-direction: row;
				.common-button {
					margin-bottom: 0;
					margin-right: 20px;
					&:last-of-type {
						margin-right: 0;
					}
				}
			}
			@media (min-width: @grid-lg) {
				justify-content: center;
				margin: 0 103px;

				.common-button{
					width: 240px;
				}
			}
			@media (min-width: @grid-xxl) {
				max-width: 1333px;
				margin: 0 auto;
			}
		}
	}
}
