@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";
@import "../../assets/styles/fonts";

.promo-code-component {
	max-width: @screen-xs;
	margin: 0 auto;
	padding: 0 10px 50px;

	.input-container {
		margin: 70px 0 0;
	}

	.content-text{
		text-align: center;
		.SourceSansPro-Regular;
		font-size: 19px;
		color: #a0a0a0;

		margin: 40px 0;
	}

	.google-reCaptcha {
		width: 304px;
		margin: 40px auto;
	}

	.buttons-container{
		display: flex;

		.common-button {
			width: calc(50% - 10px);
			margin-bottom: 10px;

			&:first-of-type{
				margin-right: 20px;
			}
		}
	}

}
