@import "../../assets/styles/variables";

.checkout-form  {
	position: relative;
	width: 100%;

	.payment-request-button{
		max-width: 200px;
	}

	.card-section {
		position:relative;
		width: 100%;

		label {
			font-family:'SourceSansPro-Regular', sans-serif;
			position:relative;
			display:block;
			text-align:center;
			line-height: 2em;
			margin-bottom: 0.5em;
		}

		.StripeElement {
			background-color: #112645 !important;
			height: 45px;
			padding: 10px 12px;
			border-radius: 8px;
			border: solid 2px @border-color-input-component;
		}

		.StripeElement--focus {
			border-color: @font-color-button;
		}

		.StripeElement .font{
			font-size: 24px;
		}

		.StripeElement--invalid {
			border-color: #fa755a;
		}

		.StripeElement--webkit-autofill {
			background-color: #fefde5 !important;
		}

		.card-details-title {
			//text-align: center;
		}
	}

	.buttons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 10px;

		.common-button{
			&:first-of-type {
				margin-bottom: 15px;
			}
		}

		@media (min-width: @grid-sm) {
			flex-direction: row;
			margin: 0 48px;

			.common-button{
				&:first-of-type {
					margin-bottom: 0;
					margin-right: 20px;
				}
			}
		}
		@media (min-width: @grid-md) {
			justify-content: flex-end;
			margin: 0 64px;

			.common-button{
				width: 240px;
			}
		}
		@media (min-width: @grid-lg) {
			margin: 0 83px;
		}
		@media (min-width: @grid-xxl) {
			margin: 0 auto;
			max-width: 1333px;
		}
	}
}

