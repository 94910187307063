@import "../../assets/styles/variables";

.paypal-button-component {
	width: 100%;
	margin: 0 0 10px;

	.separator{
		text-align: center;
		color: white;
		margin-bottom: 10px;
		font-size: 12px;
	}
}