@import '../../../assets/styles/variables';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';

.free-trial-component {
	position: relative;
	padding: 10px;
	width: 300px;
	@media (min-width: @grid-sm) {
		padding: 15px;
		width: 450px;
	}
	@media (min-width: @grid-md) {
		padding: 20px;
		width: 650px;
	}

	&:before {
		.popup-background-mixing;
	}

	.title-text {
		text-align: center;
		width: 100%;
		.Quantify-Bold;
		font-size: 34px;
		text-shadow: 0 0 0.6em @font-color-text-shadow;
	}

	.message-text {
		width: 100%;
		.SourceSansPro-Regular;
		color: @font-color-button-secondary;
		font-size: 24px;
		text-align: center;
		padding: 0 0.7em;
	}

	.image-container{
		position: relative;
		height: 300px;
		width: 310px;
		overflow: hidden;
		margin-top: -50px;
		margin-left: -30px;
		@media (min-width: @grid-sm) {
			margin-left: 35px;
		}
		@media (min-width: @grid-md) {
			margin-left: 130px;
		}

		.image-bottom {
			width: 500px;
			position: relative;
			display: block;
			height: auto;
			z-index: -1;
		}
		.image-top {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 500px;
			height: auto;
			z-index: 0;
		}
	}

	.buttons-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 10px;

		.common-button {
			&:first-of-type{
				margin-bottom: 10px;
			}
		}

		@media (min-width: @grid-sm) {
			flex-direction: row;
			.common-button {
				&:first-of-type{
					margin-bottom: 0;
					margin-right: 10px;
				}
			}
		}
	}
}