@import '../../../assets/styles/variables';

.docomo-acknowledge-component {
    max-width: @screen-xs;
    margin-left: auto;
    margin-right: auto;
        .logo-container {
            margin: 2em auto;
            text-align: center;
            .logo {
                height: auto;
                width: 15em;
            }
        }
        .page-content {
            margin: 2em;
            .warning-prompt {
                margin-top: 1em;
                background-color: #a0a0a0;
                .warning-prompt-text {
                    padding: 0.5em;
                }
            }
           
            .benefits {
                ul {
                    margin-left: 1em;
                }
                li {
                    list-style: disc;

                }
            }
            .terms-conditions {
                margin: 1em 0;
                a {
                    &:focus {
						border: 0.2em solid @focus-outline;
					}
                }
            }
            
            .button-container {
                margin: 1em;
            }
            .agree-conditions {
                display: flex;
                align-items: center;
                .agree-conditions-text {
                    padding-left: 1em;
                }
            }
        }
        
}

