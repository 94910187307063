@import '../../../assets/styles/variables';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/mixins';

.common-button {
	position:relative;
	width: 100%;
	cursor: pointer;
	user-select: none;
	border-radius: 8px;
	border: 0 solid;
	transform: skew(-10deg);
	padding: 0;
	min-width: 100px;

	.content {
		transform: skew(10deg);
		padding: 18px 0;
		.SourceSansPro-Regular;
		font-size: 24px;
		line-height: 1em;
		text-align: center;
	}

	.link-wrapper{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&.primary {
		background: @background-button-primary;
		.content{
			color: @font-color-button;
		}
	}

	&.secondary {
		background: @background-button-secondary;
		.content{
			color: @font-color-button-secondary;
		}
	}

	&.text {
		width: inherit !important;
		background: none;
		border-radius: 0;
		border-bottom: 1px solid @background-button-secondary;
		transform: none;
		.content {
			transform: none;
		}
		.back-border, .front-border {
			border: none !important;
		}
	}

	&.danger {
		background: @font-color-button-danger;
	}

	&.light-blue {
		background: @font-color-button-light-blue;
	}

	&.disabled {
		cursor: default;
		background: @background-button-disabled;
		box-shadow: none;
		.content{
			color: @font-color-button-disabled;
		}
	}

	&:focus {
		.pulsar-component {
			display: block;
		}
	}

	&.bounce-animation {
		animation: button-bounce-frames 150ms;
	}

	&.not-skewed{
		transform: initial;

		.content{
			transform: initial;
		}

		&.bounce-animation {
			animation: button-bounce-frames-not-skewed 150ms;
		}
	}

	@keyframes button-bounce-frames {
		0% { transform: scale(1) skew(-10deg); }
		50% { transform: scale(0.9)skew(-10deg); }
		100% { transform: scale(1)skew(-10deg); }
	}

	@keyframes button-bounce-frames-not-skewed {
		0% { transform: scale(1); }
		50% { transform: scale(0.9); }
		100% { transform: scale(1); }
	}
}

