@import '../../../assets/styles/variables';

.avatar-component {
	display: inline-block;
	margin:0 ;
	border: 2px solid transparent;
	overflow: hidden;
	width: 100px;
	height: 100px;

	&.selected {
		border-color:@font-color-outline-purple;
	}

	.avatar-image {
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	&.circle {
		border: 2px solid transparent;
		border-radius: 50%;

		&.selected {
			border-color:@font-color-outline-purple;
		}
	}
}