@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.display-name {
	.component-body {
		.ftui-background-mixing;
		padding-bottom: 26px;
		@media (min-width: @grid-sm) {
			padding-bottom: 54px;
		}
		@media (min-width: @grid-lg) {
			padding-bottom: 65px;
		}

		.input-field-container {
			display: block;
			margin: 45px 10px 66px;
			@media (min-width: @grid-sm) {
				margin: 45px 48px 66px;
			}
			@media (min-width: @grid-md) {
				margin: 57px 64px 76px;
			}
			@media (min-width: @grid-lg) {
				margin: 75px 232px 81px;
			}
			@media (min-width: @grid-xxl) {
				margin: 107px auto 106px;
				max-width: 856px;
			}
		}

		.buttons-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 10px;

			.common-button{
				&:first-of-type {
					margin-bottom: 15px;
				}
			}

			@media (min-width: @grid-sm) {
				flex-direction: row;
				margin: 0 48px;

				.common-button{
					&:first-of-type {
						margin-bottom: 0;
						margin-right: 20px;
					}
				}
			}
			@media (min-width: @grid-md) {
				justify-content: flex-end;
				margin: 0 64px;

				.common-button{
					width: 240px;
				}
			}
			@media (min-width: @grid-lg) {
				margin: 0 83px;
			}
			@media (min-width: @grid-xxl) {
				margin: 0 auto;
				max-width: 1333px;
			}
		}
	}
}
