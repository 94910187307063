@import "variables";

.popup-background-mixing {
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border: 0.1em solid;
	background: linear-gradient(135deg, #00356b, #00407e);
	border-image: linear-gradient(-15deg,#852095 70%, #ff465f);
	border-image-slice: 1;
	box-shadow: inset 0 0 0.3em rgba(0,0,0,0.2);
}

.ftui-background-mixing {
	background: linear-gradient(161.35deg, #000000 3.46%, #094CAF 97.91%);
}