@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.pulsar-component {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;

	.back-border,
	.front-border{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		border: solid 2px @font-color-outline-purple;
		border-radius: 8px;
	}
}
